import React from 'react';

const Basic = () => {
    return (
        <div>
            Basic
        </div>
    );
};

export default Basic;